import React, { useState, useEffect } from "react";
import { modifAlumno } from "../helpers/rutaAlumnos";
import { Modal, Button } from "react-bootstrap";
import { getAlumnos } from "../helpers/rutaAlumnos";
import moment from 'moment';

const ModalFormAlumno = ({ alumno, handleClose }) => {
  console.log(alumno);
  const id = localStorage.getItem("id");
  const [formValues, setFormValues] = useState({
    codalu: alumno.alumnos.codalu,
    apellido: alumno.alumnos.apellido,
    nombre: alumno.alumnos.nombre,
    
    dni: alumno.alumnos.dni,
    fecnac: alumno.alumnos.fecnac,
    domicilio: alumno.alumnos.domicilio,
    sede: alumno.alumnos.sede,
    autofo: alumno.alumnos.autofo,
    autopa: alumno.alumnos.autopa,
    horajar: alumno.alumnos.horajar,
    apemama: alumno.alumnos.apemama,
    emailmama: alumno.alumnos.emailmama,
    telmama: alumno.alumnos.telmama,
    ocumama: alumno.alumnos.ocumama,
    apepapa: alumno.alumnos.apepapa,
    emailpapa: alumno.alumnos.emailpapa,
    telpapa: alumno.alumnos.telpapa,
    ocupapa: alumno.alumnos.ocupapa,
    alergia: alumno.alumnos.alergia,
    observa: alumno.alumnos.observa,
    retira: alumno.alumnos.retira,

    // usuario: id,
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    console.log(alumno.alumnos._id);
    e.preventDefault();
    modifAlumno(formValues, alumno.alumnos._id).then((respuesta) => {
      console.log(respuesta);
      alert("Cambio Exitoso !!!")
      handleClose();
    });
  };

  const [alumnos, setAlumnos] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getAlumnos().then((alumnos) => {
      setAlumnos({
        data: alumnos,
        loading: false,
      });
    });
  }, []);

  const formatDateNac = moment(formValues.fecnac).format('YYYY-MM-DD'); // Ajusta el formato aquí

 /* 
 const [localidades, setLocalidades] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getLocalidades().then((localidades) => {
      setLocalidades({
        data: localidades,
        loading: false,
      });
    });
  }, []);

  const [rutas, setRutas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getRutas().then((rutas) => {
      setRutas({
        data: rutas,
        loading: false,
      });
    });
  }, []);
  */

  return (
    <>
      {!alumnos.loading && (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            {/* <div className="form-group">
            <label>Cod. Cliente</label>
            <input 
              rows="1"
              type="text"
              className="form-control"
              name="codcli"
              maxLength="4"
              minLength="1"
              required
              value={formValues.codcli}
              onChange={handleChange}
            />
          </div> */}

            <div className="form-group mt-3">
              <label>Apellido</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="apellido"
                maxLength="50"
                minLength="1"
                required
                value={formValues.apellido}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Nombre</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="nombre"
                maxLength="50"
                minLength="1"
                required
                value={formValues.nombre}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>DNI</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="dni"
                maxLength="8"
                minLength="8"
                required
                value={formValues.dni}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Fec Nacimiento</label>
              <input
                rows="1"
                type="date"
                className="form-control"
                name="fecnac"
                required
                value={formatDateNac}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Domicilio</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="domicilio"
                maxLength="30"
                minLength="5"
                required
                value={formValues.domicilio}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
                  <label className="">Sede</label>
                  <select
                    className="form-control"
                    name="sede"
                    value={formValues.sede}
                    onChange={handleChange}
                    required
                  >
                    <option selected value="">
                      Elija opción
                    </option>
                    <option>Sede Norte</option>
                    <option>Sede Sur</option>
                  </select>
                </div>

                <div className="form-group mt-3">
                <label className="">Horario Jardin</label>
                <input
                  type="text"
                  className="form-control"
                  name="horajar"
                  maxLength="50"
                  required
                  value={formValues.horajar}
                  onChange={handleChange}
                />
                {console.log(formValues.horajar)}
              </div>

              <div className="form-group mt-3">
                  <label className="">Autoriza Foto Redes</label>
                  <select
                    className="form-control"
                    name="autofo"
                    value={formValues.autofo}
                    onChange={handleChange}
                    required
                  >
                    <option selected value="">
                      Elija opción
                    </option>
                    <option>SI</option>
                    <option>NO</option>
                  </select>
                </div>

                <div className="form-group mt-3">
                  <label className="">Autoriza Cambio Pañales</label>
                  <select
                    className="form-control"
                    name="autopa"
                    value={formValues.autopa}
                    onChange={handleChange}
                    required
                  >
                    <option selected value="">
                      Elija opción
                    </option>
                    <option>SI</option>
                    <option>NO</option>
                  </select>
                </div>


            <div className="form-group mt-3">
              <label>Apellido Nombre Mama</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="apemama"
                maxLength="50"
                minLength="3"
                required
                value={formValues.apemama}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Email Mama</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="emailmama"
                maxLength="50"
                minLength="5"
                required
                value={formValues.emailmama}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Telefono Mama</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="telmama"
                maxLength="50"
                minLength="5"
                required
                value={formValues.telmama}
                onChange={handleChange}
              />
            </div>
            
            <div className="form-group mt-3">
                <label className="">Ocupacion Mama</label>
                <input
                  type="text"
                  className="form-control"
                  name="ocumama"
                  maxLength="50"
                  required
                  value={formValues.ocumama}
                  onChange={handleChange}
                />
                {console.log(formValues.ocumama)}
              </div>
            
            <div className="form-group mt-3">
              <label>Apellido Nombre Papa</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="apepapa"
                maxLength="50"
                minLength="3"
                required
                value={formValues.apepapa}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Email Papa</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="emailpapa"
                maxLength="50"
                minLength="5"
                required
                value={formValues.emailpapa}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Telefono Papa</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="telpapa"
                maxLength="50"
                minLength="5"
                required
                value={formValues.telpapa}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
                <label className="">Ocupacion Papa</label>
                <input
                  type="text"
                  className="form-control"
                  name="ocupapa"
                  maxLength="50"
                  required
                  value={formValues.ocupapa}
                  onChange={handleChange}
                />
                {console.log(formValues.ocupapa)}
            </div>

            <div className="form-group mt-3">
                <label className="">Alergias</label>
                  <input
                    type="text"
                    className="form-control"
                    name="alergia"
                    maxLength="50"
                    required
                    value={formValues.alergia}
                    onChange={handleChange}
                  />
                  {console.log(formValues.alergia)}
              </div>

              <div className="form-group mt-3">
                <label className="">Observaciones</label>
                  <input
                    type="text"
                    className="form-control"
                    name="observa"
                    maxLength="50"
                    required
                    value={formValues.observa}
                    onChange={handleChange}
                  />
                  {console.log(formValues.observa)}
              </div>

              <div className="form-group mt-3">
                <label className="">Quien Retira</label>
                  <input
                    type="text"
                    className="form-control"
                    name="retira"
                    maxLength="50"
                    required
                    value={formValues.retira}
                    onChange={handleChange}
                  />
                  {console.log(formValues.retira)}
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="dark">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      )}
      ;
    </>
  );
};

export default ModalFormAlumno;
