import React, { useState, useEffect } from "react";
import { modifGasto } from "../helpers/rutaGastos";
import { Modal, Button } from "react-bootstrap";
import { getGastos } from "../helpers/rutaGastos";
import { getProducservs } from "../helpers/rutaProducservs";
import { getProveedores } from "../helpers/rutaProveedores";
import moment from 'moment';

const ModalFormGastoApro = ({ gasto, handleClose }) => {
  console.log(gasto);
  const id = localStorage.getItem("id");
  const [formValues, setFormValues] = useState({
    nrocomp: gasto.gastos.nrocomp,
    codprov: gasto.gastos.codprov,
    codprod: gasto.gastos.codprod,
    fechacomp: gasto.gastos.fechacomp,
    monto: gasto.gastos.monto,
    tipo: gasto.gastos.tipo,
    fechapago: gasto.gastos.fechapago,
    montopagado: gasto.gastos.montopagado,

    // usuario: id,
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    console.log(gasto.gastos._id);
    e.preventDefault();
    modifGasto(formValues, gasto.gastos._id).then((respuesta) => {
      console.log(respuesta);
      handleClose();
    });
  };

  const [gastos, setGastos] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getGastos().then((gastos) => {
      setGastos({
        data: gastos,
        loading: false,
      });
    });
  }, []);

  const [proveedores, setProveedores] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getProveedores().then((proveedores) => {
      setProveedores({
        data: proveedores,
        loading: false,
      });
    });
  }, []);

  const [producservs, setProducservs] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getProducservs().then((producservs) => {
      setProducservs({
        data: producservs,
        loading: false,
      });
    });
  }, []);

  const formatDatePago = moment(formValues.fechapago).format('YYYY-MM-DD'); // Ajusta el formato aquí


  return (
    <>
      {!gastos.loading && !producservs.loading && !proveedores.loading && (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            {/* <div className="form-group mt-3">
              <label>Nro de Comp</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="nrocomp"
                maxLength="50"
                minLength="5"
                required
                value={formValues.nrocomp}
                onChange={handleChange}
              
              />
            </div> */}

            {/* <div className="form-group mt-3">
              <label>Proveedor</label>
              <select
                className="form-control"
                name="codprov"
                value={formValues.codprov}
                onChange={handleChange}
                required
              >
                <option selected value="">
                  Elija la opción adecuada
                </option>
                {proveedores.data.proveedores.map((proveedor) => (
                  <option value={proveedor._id}>{proveedor.razonsocial}</option>
                ))}
              </select>
            </div> */}

            {/* <div className="form-group mt-3">
              <label>Producto</label>
              <select
                className="form-control"
                name="codprod"
                value={formValues.codprod}
                onChange={handleChange}
                required
              >
                <option selected value="">
                  Elija la opción adecuada
                </option>
                {producservs.data.producservs.map((producserv) => (
                  <option value={producserv._id}>{producserv.descripcion}</option>
                ))}
              </select>
            </div> */}

            {/* <div className="form-group col-sm-2">
                <label className="mt-4">Fecha Pago</label>
                <input
                  type="date"
                  className="form-control"
                  name="fechapago"
                  id="fechacomp"
                  maxLength="30"
                  required
                  value={formValues.fechapago}
                  onChange={handleChange}
                />
                {console.log(formValues.fechapago)}
              </div> */}

            <div className="form-group mt-3">
              <label>Fecha Pago</label>
              <input
                rows="1"
                type="date"
                className="form-control"
                name="fechapago"
                required
                value={formatDatePago}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Monto Pagado</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="montopagado"
                maxLength="100"
                required
                value={formValues.montopagado}
                onChange={handleChange}
              />
            </div>

            {/* <div className="form-group mt-3">
              <label className="">Sede</label>
              <select
                className="form-control"
                name="tipo"
                value={formValues.tipo}
                onChange={handleChange}
                required
              >
              <option selected value="">
                Elija opción
              </option>
              <option>Sede Norte</option>
                <option>Sede Sur</option>
              </select>
            </div> */}


          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="dark">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      )}
      ;
    </>
  );
};

export default ModalFormGastoApro;
