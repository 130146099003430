import React from "react";
import { Modal } from "react-bootstrap";
import ModalFormGastoApro from "./ModalFormGastoApro";

const ModalGastoApro = ({ show, handleClose, gasto }) => {
  console.log(gasto);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Aprobar y Cargar Gasto</Modal.Title>
      </Modal.Header>
      <ModalFormGastoApro gasto={gasto} handleClose={handleClose} />
    </Modal>
  );
};

export default ModalGastoApro;
