import React, { useState, useEffect } from "react";
import { modifProducserv } from "../helpers/rutaProducservs";
import { Modal, Button } from "react-bootstrap";
import { getProducservs } from "../helpers/rutaProducservs";
import { getCuentas } from "../helpers/rutaCuentas";
// import { getUnidades } from "../helpers/rutaUnidades";

const ModalFormProducserv = ({ producserv, handleClose }) => {
  console.log(producserv);
  const id = localStorage.getItem("id");
  const [formValues, setFormValues] = useState({
    //codprod: producserv.producservs.codprod,
    descripcion: producserv.producservs.descripcion,
    cuenta: producserv.producservs.cuenta,
    tipo: producserv.producservs.tipo,
    // usuario: id,
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    console.log(producserv.producservs._id);
    e.preventDefault();
    modifProducserv(formValues, producserv.producservs._id).then(
      (respuesta) => {
        console.log(respuesta);
        handleClose();
      }
    );
  };

  const [producservs, setProducservs] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getProducservs().then((producservs) => {
      setProducservs({
        data: producservs,
        loading: false,
      });
    });
  }, []);

  const [cuentas, setCuentas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getCuentas().then((cuentas) => {
      setCuentas({
        data: cuentas,
        loading: false,
      });
    });
  }, []);

  return (
    <>
      {!producservs.loading && !cuentas.loading && (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            {/* <div className="form-group">
              <label>Cod. Prod</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="codprod"
                maxLength="11"
                minLength="1"
                required
                value={formValues.codprod}
                onChange={handleChange}
              />
            </div> */}

            <div className="form-group mt-3">
              <label>Descripcion</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="descripcion"
                maxLength="50"
                minLength="5"
                required
                value={formValues.descripcion}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Cuenta</label>
              <select
                className="form-control"
                name="cuenta"
                value={formValues.cuenta}
                onChange={handleChange}
                required
              >
                <option selected value="">
                  Elija la opción adecuada
                </option>
                {cuentas.data.cuentas.map((cuenta) => (
                  <option value={cuenta._id}>{cuenta.cuenta}</option>
                ))}
              </select>
            </div>

            
            <div className="form-group mt-3">
                <label className="">Tipo</label>
                <select
                  className="form-control"
                  name="tipo"
                  value={formValues.tipo}
                  onChange={handleChange}
                  required
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  <option>PRODUCTO</option>
                  <option>SERVICIO</option>
                </select>
              </div>


            {/* <div className="form-group mt-3">
              <label>Stock Actual</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="stkactual"
                maxLength="30"
                minLength="1"
                required
                value={formValues.stkactual}
                onChange={handleChange}
              />
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="dark">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      )}
      ;
    </>
  );
};

export default ModalFormProducserv;
