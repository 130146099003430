import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getCuentas, delCuenta, getCuentaId } from "../helpers/rutaCuentas";
import ModalCuenta from "./ModalCuenta";
import "../css/tablecuentas.css";

const TableCuentas = () => {
  let id_cuenta= "";

  const [cuentas, setCuentas] = useState({
    data: {},
    loading: true,
  });

  const [cuenta, setCuenta] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    consultaCuentas();
  }, []);

  const consultaCuentas = () => {
    getCuentas().then((datos) => {
      console.log(datos);
      setCuentas({
        data: datos,
        loading: false,
      });
    });
  };

  // console.log(comandas.data[0]);

  const handleClose = () => {
    setShow(false);
    consultaCuentas();
  };

  const handleShow = () => setShow(true);

  const modificaCuenta= (id) => {
    id_cuenta = id;
    getCuentaId(id_cuenta).then((resp) => {
      console.log(resp);
      setCuenta(resp);

      handleShow();
    });
  };

  const deleteCuenta = (id) => {
    let validar = window.confirm("está seguro que desea borrar la cuenta?");
    if (validar) {
      delCuenta(id).then((resp) => {
        consultaCuentas();
      });
    }
  };

  let count = 0;

  return (
    <div className="table-container">
      {!cuentas.loading && (
        <>
          <div className="col-12 mt-4"></div>
          <div>
            <Table striped bordered hover className="table-container">
              <thead>
                <tr className="header">
                  <th className="th">Modif/Elim</th>
                  <th className="cli">Cuenta</th>
                  <th className="cli">Grupo</th>
                  <th className="valor">Valor</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {cuentas.data.cuentas.map((cuenta) => (
                  <>
                    {cuenta.activo && (
                      <tr key={cuenta._id}>
                        <td className="sticky1">
                          <button
                            id="acepto"
                            className="btn btn-primary"
                            onClick={() => {
                              modificaCuenta(cuenta._id);
                            }}
                          >
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            id="acepto"
                            className="btn btn-danger ml-2"
                            onClick={() => {
                              deleteCuenta(cuenta._id);
                            }}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                        <td>{cuenta.cuenta}</td>
                        <td>{cuenta.grupo.grupo}</td>
                        <td>{cuenta.valor}</td>
                        {/* <td>{cliente.lista.lista}</td>
                    <td>{cliente.codprod.descripcion}</td> */}
                        {/* <td>{usuario.nombre}</td>*/}
                        {/* {(count = count + 1)} */}

                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
            {/* <h1>{count}</h1> */}
          </div>
          <ModalCuenta
            show={show}
            handleClose={handleClose}
            cuenta={cuenta}
          />
        </>
      )}
    </div>
  );
};

export default TableCuentas;
