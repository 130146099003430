import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getGastos, delGasto, getGastoId } from "../helpers/rutaGastos";
import { getProducservs } from "../helpers/rutaProducservs";
import ModalGasto from "./ModalGasto";
import "../css/tablegastos.css";

const TableGastos = () => {
  let id_gasto= "";

  const [gastos, setGastos] = useState({
    data: {},
    loading: true,
  });

  const [gasto, setGasto] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    consultaGastos();
  }, []);

  const consultaGastos = () => {
    getGastos().then((datos) => {
      console.log(datos);
      setGastos({
        data: datos,
        loading: false,
      });
    });
  };

  // console.log(comandas.data[0]);

  const handleClose = () => {
    setShow(false);
    consultaGastos();
  };

  const handleShow = () => setShow(true);

  const modificaGasto= (id) => {
    id_gasto = id;
    getGastoId(id_gasto).then((resp) => {
      console.log(resp);
      setGasto(resp);

      handleShow();
    });
  };

  const deleteGasto = (id) => {
    let validar = window.confirm("está seguro que desea borrar el gasto?");
    if (validar) {
      delGasto(id).then((resp) => {
        consultaGastos();
      });
    }
  };



  let count = 0;

  return (
    <div className="table-container">
      {!gastos.loading  && (
        <>
          <div className="col-12 mt-4"></div>
          <div>
            <Table striped bordered hover className="table-container">
              <thead>
                <tr className="header">
                  <th className="th">Modif/Elim</th>
                  <th className="cli">Nro Comprob</th>
                  <th className="cli">Fecha Comprob</th>
                  <th className="cli">Proveedor</th>
                  <th className="cli">Producto</th>
                  <th className="">Monto</th>
                  <th className="cli">Sede</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {gastos.data.gastos.map((gasto) => (
                  <>
                    {gasto.activo && (
                      <tr key={gasto._id}>
                        <td className="sticky1">
                          <button
                            id="acepto"
                            className="btn btn-primary"
                            onClick={() => {
                              modificaGasto(gasto._id);
                            }}
                          >
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            id="acepto"
                            className="btn btn-danger ml-2"
                            onClick={() => {
                              deleteGasto(gasto._id);
                            }}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                        <td>{gasto.nrocomp}</td>
                        <td>{gasto.fechacomp.toLocaleString("en-GB").substr(0, 10)}</td>
                        <td>{gasto.codprov.razonsocial}</td>
                        <td>{gasto.codprod.descripcion}</td>
                        <td style={{ textAlign: 'right' }} >{gasto.monto}</td>
                        <td>{gasto.tipo}</td>
                        {/* <td>{cliente.lista.lista}</td>
                    <td>{cliente.codprod.descripcion}</td> */}
                        {/* <td>{usuario.nombre}</td>*/}
                        {/* {(count = count + 1)} */}

                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
            {/* <h1>{count}</h1> */}
          </div>
          <ModalGasto
            show={show}
            handleClose={handleClose}
            gasto={gasto}
          />
        </>
      )}
    </div>
  );
};

export default TableGastos;
