import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getGrupos, delGrupo, getGrupoId } from "../helpers/rutaGrupos";
import ModalGrupo from "./ModalGrupo";
import "../css/tablegrupos.css";

const TableGrupos = () => {
  let id_grupo = "";

  const [grupos, setGrupos] = useState({
    data: {},
    loading: true,
  });

  const [grupo, setGrupo] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    consultaGrupos();
  }, []);

  const consultaGrupos = (desde) => {
    getGrupos(desde, 1000).then((datos) => {
      console.log(datos);
      setGrupos({
        data: datos,
        loading: false,
      });
    });
  };

  // console.log(comandas.data[0]);

  const handleClose = () => {
    setShow(false);
    consultaGrupos();
  };

  const handleShow = () => setShow(true);

  const modificaGrupo = (id) => {
    id_grupo = id;
    getGrupoId(id_grupo).then((resp) => {
      console.log(resp);
      setGrupo(resp);

      handleShow();
    });
  };

  const deleteGrupo = (id) => {
    let validar = window.confirm("está seguro que desea borrar el Grupo?");
    if (validar) {
      delGrupo(id).then((resp) => {
        consultaGrupos();
      });
    }
  };

  let count = 0;

  return (
    <div className="table-container">
      {!grupos.loading && (
        <>
          <div className="col-12 mt-4"></div>
          <div>
            <Table striped bordered hover className="table sticky">
              <thead>
                <tr className="header">
                  <th className="borra sticky">Modif/Elim</th>
                  <th className="grupo sticky">Grupos</th>
                  <th className="sticky"></th>
                </tr>
              </thead>

              <tbody>
                {grupos.data.grupos.map((grupo) => (
                  <>
                    {grupo.activo && (
                      <tr key={grupo._id}>
                        
                        {/* <td>{cliente.lista.lista}</td>
                        <td>{cliente.codprod.descripcion}</td> */}
                        {/* <td>{usuario.nombre}</td>*/}
                        {/* {(count = count + 1)} */}
                        <td className="borra">
                          <button
                            id="acepto"
                            className="btn btn-primary"
                            onClick={() => {
                              modificaGrupo(grupo._id);
                            }}
                          >
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            id="acepto"
                            className="btn btn-danger ml-2"
                            onClick={() => {
                              deleteGrupo(grupo._id);
                            }}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                        <td className="grupo">{grupo.grupo}</td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
            {/* <h1>{count}</h1> */}
          </div>
          <ModalGrupo show={show} handleClose={handleClose} grupo={grupo} />
        </>
      )}
    </div>
  );
};

export default TableGrupos;
