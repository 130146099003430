import React from "react";
import { Modal } from "react-bootstrap";
import ModalFormCuenta from "./ModalFormCuenta";

const ModalCuenta = ({ show, handleClose, cuenta }) => {
  console.log(cuenta);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modificar Cuenta</Modal.Title>
      </Modal.Header>
      <ModalFormCuenta cuenta={cuenta} handleClose={handleClose} />
    </Modal>
  );
};

export default ModalCuenta;
