import React, { useState, useEffect } from "react";
import { modifDocente } from "../helpers/rutaDocentes";
import { Modal, Button } from "react-bootstrap";
import { getDocentes } from "../helpers/rutaDocentes";
import moment from 'moment';
//import { format } from 'date-fns';

const ModalFormDocente = ({ docente, handleClose }) => {
  console.log(docente);
  const id = localStorage.getItem("id");
  const [formValues, setFormValues] = useState({
    coddoc: docente.docentes.coddoc,
    apellido: docente.docentes.apellido,
    nombre: docente.docentes.nombre,
    dni: docente.docentes.dni,
    fecnac: docente.docentes.fecnac,
    fecing: docente.docentes.fecing,
    domicilio: docente.docentes.domicilio,
    email: docente.docentes.email,
    tel: docente.docentes.tel,
    horario: docente.docentes.horario,
    // usuario: id,
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    console.log(docente.docentes._id);
    e.preventDefault();
    modifDocente(formValues, docente.docentes._id).then((respuesta) => {
      console.log(respuesta);
      alert("Cambio Exitoso !!!")
      handleClose();
    });
  };

  const [docentes, setDocentes] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getDocentes().then((docentes) => {
      setDocentes({
        data: docentes,
        loading: false,
      });
    });
  }, []);

  // const formatDate = (isoDate) => {
  //   const date = new Date(isoDate);
  //   return date.toLocaleDateString(); // Puedes ajustar el formato aquí
  // };

  const formatDateNac = moment(formValues.fecnac).format('YYYY-MM-DD'); // Ajusta el formato aquí
  const formatDateIng = moment(formValues.fecing).format('YYYY-MM-DD'); // Ajusta el formato aquí

 /* 
 const [localidades, setLocalidades] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getLocalidades().then((localidades) => {
      setLocalidades({
        data: localidades,
        loading: false,
      });
    });
  }, []);

  const [rutas, setRutas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getRutas().then((rutas) => {
      setRutas({
        data: rutas,
        loading: false,
      });
    });
  }, []);
  */

  return (
    <>
      {!docentes.loading && (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            {/* <div className="form-group">
            <label>Cod. Cliente</label>
            <input 
              rows="1"
              type="text"
              className="form-control"
              name="codcli"
              maxLength="4"
              minLength="1"
              required
              value={formValues.codcli}
              onChange={handleChange}
            />
          </div> */}

            <div className="form-group mt-3">
              <label>Apellido</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="apellido"
                maxLength="50"
                minLength="1"
                required
                value={formValues.apellido}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Nombre</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="nombre"
                maxLength="50"
                minLength="1"
                required
                value={formValues.nombre}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>DNI</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="dni"
                maxLength="8"
                minLength="8"
                required
                value={formValues.dni}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Domicilio</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="domicilio"
                maxLength="30"
                minLength="5"
                required
                value={formValues.domicilio}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Fec Nacimiento</label>
              <input
                rows="1"
                type="date"
                className="form-control"
                name="fecnac"
                required
                value={formatDateNac}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Fec Ingreso</label>
              <input
                rows="1"
                type="date"
                className="form-control"
                name="fecing"
                required
                value={formatDateIng}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Email</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="email"
                maxLength="50"
                minLength="5"
                required
                value={formValues.email}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3">
              <label>Telefono</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="tel"
                maxLength="50"
                minLength="5"
                required
                value={formValues.tel}
                onChange={handleChange}
              />
            </div>
            
            <div className="form-group mt-3">
              <label>Horario</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="horario"
                maxLength="50"
                minLength="5"
                required
                value={formValues.horario}
                onChange={handleChange}
              />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="dark">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      )}
      ;
    </>
  );
};

export default ModalFormDocente;
