import React from "react";
import { Modal } from "react-bootstrap";
import ModalFormDocente from "./ModalFormDocente";

const ModalDocente = ({ show, handleClose, docente }) => {
  console.log(docente)
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>MODIFICAR DATOS DEL DOCENTE</Modal.Title>
      </Modal.Header>
      <ModalFormDocente docente={docente} handleClose={handleClose} />
    </Modal>
  );
};

export default ModalDocente;
