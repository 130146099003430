import React, { useState, useEffect } from "react";
import {
  addDocente,
  getDocente,
  delDocente,
  getDocenteId,
} from "../helpers/rutaDocentes";


//import { getLocalidades } from "../helpers/rutaLocalidades";
//import { getRutas } from "../helpers/rutaRutas";
//import { getIva } from "../helpers/rutaIva";


//import "../css/addDocenteform.css";

const AddDocenteForm = ({ setShow }) => {
 // const id = JSON.parse(localStorage.getItem("id"));
  const [formValues, setFormValues] = useState({
    coddoc: "",
    apellido: "",
    nombre: "",
    dni: "",
    fecnac: "",
    fecing: "",
    domicilio: "",
    email: "",
    tel: "",
    horario: "",
  //  usuario: id,
  });
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addDocente(formValues).then((resp) => {
      console.log(resp);
      setFormValues({
        coddoc: "",
        apellido: "",
        nombre: "",
        dni: "",
        fecnac: "",
        fecing: "",
        domicilio: "",
        email: "",
        tel: "",
        horario: "",
        //usuario: id,

      });
      alert("Grabado Exitoso !!!")
      //   setShow(false);
    });
  };

  /*
  const [localidades, setLocalidades] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getLocalidades().then((localidades) => {
      setLocalidades({
        data: localidades,
        loading: false,
      });
    });
  }, []);
  */

  /*
  const [iva, setIva] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getIva().then((iva) => {
      setIva({
        data: iva,
        loading: false,
      });
    });
  }, []);
  */

  /*
  const [rutas, setRutas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getRutas().then((rutas) => {
      setRutas({
        data: rutas,
        loading: false,
      });
    });
  }, []);
  */

  return (
    <>
      {//!localidades.loading && !iva.loading && !rutas.loading && (
        <div className="container">
          {/* {localidades.data.localidades.map((localidad) => (
            <h3>{localidad.localidad}</h3>
          ))} */}

          <form onSubmit={handleSubmit}>
            <div className="form-row">
              {/* <div className="form-group mt-3 col-sm-2">
                <label className="">Codigo Cliente</label>
                <input
                  type="text"
                  className="form-control"
                  name="codcli"
                  maxLength="20"
                  required
                  value={formValues.codcli}
                  onChange={handleChange}
                />
                {console.log(formValues.codcli)}
              </div> */}

              <div className="form-group mt-3 col-sm-5">
                <label className="">Apellido del Docente</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellido"
                  maxLength="50"
                  required
                  value={formValues.apellido}
                  onChange={handleChange}
                />
                {console.log(formValues.apellido)}
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Nombre del Docente</label>
                <input
                  type="text"
                  className="form-control"
                  name="nombre"
                  maxLength="50"
                  required
                  value={formValues.nombre}
                  onChange={handleChange}
                />
                {console.log(formValues.nombre)}
              </div>

              <div className="form-group mt-3 col-sm-3">
                <label className="">DNI del Docente</label>
                <input
                  type="text"
                  className="form-control"
                  name="dni"
                  maxLength="8"
                  required
                  value={formValues.dni}
                  onChange={handleChange}
                />
                {console.log(formValues.dni)}
              </div>

              <div className="form-group col-sm-3">
                <label className="mt-4">Fecha Nac. Docente</label>
                <input
                  type="date"
                  className="form-control"
                  name="fecnac"
                  id="fecnac"
                  maxLength="30"
                  required
                  value={formValues.fecnac}
                  onChange={handleChange}
                />
                {console.log(formValues.fecnac)}
              </div>

              <div className="form-group col-sm-3">
                <label className="mt-4">Fecha Ingreso Docente</label>
                <input
                  type="date"
                  className="form-control"
                  name="fecing"
                  id="fecing"
                  maxLength="30"
                  required
                  value={formValues.fecing}
                  onChange={handleChange}
                />
                {console.log(formValues.fecing)}
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Domicilio</label>
                <input
                  type="text"
                  className="form-control"
                  name="domicilio"
                  maxLength="50"
                  required
                  value={formValues.domicilio}
                  onChange={handleChange}
                />
                {console.log(formValues.domicilio)}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group mt-3 col-sm-4">
                <label className="">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  maxLength="50"
                  required
                  value={formValues.email}
                  onChange={handleChange}
                />
                {console.log(formValues.email)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Telefono</label>
                <input
                  type="text"
                  className="form-control"
                  name="tel"
                  maxLength="50"
                  required
                  value={formValues.tel}
                  onChange={handleChange}
                />
                {console.log(formValues.tel)}
              </div>

            </div>

            <div className="form-row">
              <div className="form-group mt-3 col-sm-4">
                <label className="">Horario</label>
                <input
                  type="text"
                  className="form-control"
                  name="horario"
                  maxLength="50"
                  required
                  value={formValues.horario}
                  onChange={handleChange}
                />
                {console.log(formValues.horario)}
              </div>

            </div>

              {/*
              <div className="form-group mt-3 col-sm-2">
                <label className="">Localidad</label>
                <select
                  className="form-control"
                  name="localidad"
                  value={formValues.localidad}
                  onChange={handleChange}
                  required
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  {localidades.data.localidades.map((localidad) => (
                    <option value={localidad._id}>{localidad.localidad}</option>
                  ))}
                </select>
              </div>*/}

            <div className="form-row">
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-dark mt-5 mb-3"
                id="button"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      //)
      }
    </>
  );
};

export default AddDocenteForm;


