import React from "react";
import { Modal } from "react-bootstrap";
import ModalFormGrupo from "./ModalFormGrupo";

const ModalGrupo = ({ show, handleClose, grupo }) => {
  console.log(grupo);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modificar Grupo</Modal.Title>
      </Modal.Header>
      <ModalFormGrupo grupo={grupo} handleClose={handleClose} />
    </Modal>
  );
};

export default ModalGrupo;
