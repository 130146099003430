import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode"; //Paquete para decodificar el Token
import { Link, useLocation, useHistory } from "react-router-dom";
import { getComandas } from "../helpers/rutaComandas";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../images/logojardin.jpg";
import "../css/navbar.css";

const NavBar = () => {
  //Defino location e history
  const location = useLocation();
  const history = useHistory();

  //estado para manejar el usuario
  const [user, setUser] = useState("Iniciar sesión");

  const [payload, setPayload] = useState({
    role: "",
  });

  //estado para manejar los datos de los cursos
  const [comandas, setComandas] = useState({
    data: {},
    loading: true,
  });

  //Si cambia la locación asigno a user el valor de localstorage
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("usuario")) || "Iniciar Sesión");
    //ActualizarData();
    checkToken();
  }, [location]);

  //Cuando monto navbar se cargan los cursos
  useEffect(() => {


    //esto bloque para jardines solo la linea siguiente
    //ActualizarData();
    //  getComandas().then((datos) => {
    //    setComandas({
    //     data: datos,
    //      loading: false,
    //    });
    //  });
  }, []);

 //esto bloque para jardines Actualiza data
 // const ActualizarData = () => {
 //   getComandas(0, 100).then((datos) => {
 //     setComandas({
  //      data: datos,
  //      loading: false,
  //    });
  //  });
 // };

  //Manejo el deslogueo de la web
  const handleLogin = () => {
    localStorage.setItem("token", JSON.stringify(""));
    localStorage.setItem("id", JSON.stringify(""));
    localStorage.setItem("usuario", JSON.stringify("Iniciar Sesión"));
    setUser(JSON.parse(localStorage.getItem("usuario")));
    setPayload({ role: "" });
    history.push("/login");
  };

  const checkToken = () => {
    let token = JSON.parse(localStorage.getItem("token")) || "";
    if (token.length > 0) {
      let token_decode = jwt_decode(localStorage.getItem("token")); //Obteniendo los datos del payload
      setPayload(token_decode.usuario);
    }
  };

  return (
    <div className="container">
      <div id="navBar" className="navBar">

        <div>


          
        </div>
        <Navbar bg="light" expand="lg">
          <img src={logo} alt="logo" />
          <Link className="nav" to="/">
            <Navbar.Brand className="brand">Espacio Rayuela</Navbar.Brand>
          </Link>
          
          <Navbar.Toggle id="hamburguesa" aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav-light"> 
                
            <Nav>
              {(payload.role === "ADMIN_ROLE" || payload.role === "ADMIN_SUP") && (
                <NavDropdown.Item href="" classename="nav-link3">
                                     -
                </NavDropdown.Item>           
               )}

              {(payload.role === "ADMIN_ROLE" || payload.role === "ADMIN_SUP") && (
                <NavDropdown.Item href="/alumnos" classename="nav-link3">
                  Alumnos
                </NavDropdown.Item>           
               )}

              {(payload.role === "ADMIN_ROLE" || payload.role === "ADMIN_SUP") && (
                <NavDropdown.Item href="/docentes" classename="nav-link3">
                  Docentes
                </NavDropdown.Item>           
               )}

              {(payload.role === "ADMIN_ROLE" || payload.role === "ADMIN_SUP") && (
                <NavDropdown.Item href="" classename="nav-link3">
                  Administracion
                </NavDropdown.Item>           
               )}

              {(payload.role === "ADMIN_ROLE" || payload.role === "ADMIN_SUP") && (
                <NavDropdown.Item href="" classename="nav-link3">
                  Sueldos
                </NavDropdown.Item>           
               )}

              {(payload.role === "ADMIN_ROLE" || payload.role === "ADMIN_SUP") && (
                <NavDropdown.Item href="" classename="nav-link3">
                  Estado de Cuentas
                </NavDropdown.Item>           
               )}

              {(payload.role === "ADMIN_ROLE" || payload.role === "ADMIN_SUP") && (
                <NavDropdown.Item href="/homealtas" classename="nav-link3">
                  Gestion Altas
                </NavDropdown.Item>           
               )}


              {(payload.role === "ADMIN_ROLE" || payload.role === "ADMIN_SUP") && (
                <NavDropdown.Item href="/homeimpresion" classename="nav-link3">
                  Informes
                </NavDropdown.Item>           
               )}

              {/* {(payload.role === "ADMIN_ROLE" || payload.role === "ADMIN_SUP") && (
                 <NavDropdown title="Informes" id="navbarScrollingDropdown">
                  <NavDropdown.Item href="/InformeAlumnos" classename="nav-link3">
                    Alumnos
                  </NavDropdown.Item>   
                  <NavDropdown.Item href="/InformeAlumnos" classename="nav-link3">
                    Docentes
                  </NavDropdown.Item>
                </NavDropdown>
                        
               )} */}
              
              {(payload.role === "ADMIN_ROLE" || payload.role === "ADMIN_SUP") && (
                
                <NavDropdown.Item href="" classename="nav-link3">
                  Acerca de
                </NavDropdown.Item>           
                
               )}

            
              {/*payload.role === "ADMIN_SUP" && (
                <Link to="/camiones" className="nav-link ml-3 mt-2">
                  Distribucion
                </Link>
              )*/}

              {/*payload.role === "USER_CAM" && (
                <Link to="/mapas" className="nav-link ml-3 mt-2">
                  Mapa
                </Link>
              )*/}
            

            </Nav>
            {/* {payload.role === "ADMIN_ROLE" && (
              <Link
                to="/admin"
                id="user"
                className="text-decoration-none text-muted ml-5 mr-3 "
              >
                Administrador
              </Link>
            )} */}
            <button
              id="booton"
              className="btn btn-outline-info"
              onClick={handleLogin}
            >
              {user}
            </button>
     
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default NavBar;
