import React from "react";
import { Modal } from "react-bootstrap";
import ModalFormAlumno from "./ModalFormAlumno";

const ModalAlumno = ({ show, handleClose, alumno }) => {
  console.log(alumno)
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>MODIFICAR DATOS DEL ALUMNO</Modal.Title>
      </Modal.Header>
      <ModalFormAlumno alumno={alumno} handleClose={handleClose} />
    </Modal>
  );
};

export default ModalAlumno;
