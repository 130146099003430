import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import axios from "axios";
import ReactHTMLDatalist from "react-html-datalist";
// import { addCliente } from "../helpers/rutaClientes";
// import { addComanda } from "../helpers/rutaComandas";
// import ActualizaComanda from "../components/ActualizaComanda"
import ActualizaComanda from "./ActualizaComanda";

import AddFormDynamics from "./AddFormDynamics";
// import { getUltimacomandas } from "../helpers/rutaUltimacomandas";
import { getClientesActivos } from "../helpers/rutaClientes";
import { getUsuarios } from "../helpers/rutaUsuarios";
// import { getProducservs } from "../helpers/rutaProducservs";
import { getListas } from "../helpers/rutaListas";
import "../css/addcomandaform.css";

const AddComandaForm = () => {
  const [guardar, setGuardar] = useState(false);
  const [show, setShow] = useState(false);
  const [showDatesRange, setShowDatesRange] = useState(false);
  const [nrodecomanda, setNrodecomanda] = useState("");
  const [clienteSelect, setclienteSelect] = useState("Consumidor Final")
  const [fechaHasta, setFechaHasta] = useState('')

  const [detail, setDetail] = useState({ food_id: "" });
  let { clienterazon } = useParams();
  const handleChange = (e) => {
    setDetail({ ...detail, [e.target.name]: e.target.value });
    console.log(e.target.value);
  };

  const getClienteSelec = () => {
    setclienteSelect(document.getElementById('clienteSelect').value);
    setShowDatesRange(true);


  };

  const [codcli, setCodcli] = useState("");
  const [razonsocial, setRazonsocial] = useState("");

  // useEffect(() => {
  //   axios("https://backditripollo.us-3.evennode.com/clientes")
  //     .then((res) => {
  //       setCodcli(res);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const [lista, setLista] = useState("");

  const [usuario, setUsuario] = useState("");

  const [formValues, setFormValues] = useState({
    nrodecomanda: "",
    codcli: "",
    lista: "",
    // usuario: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // localStorage.setItem("nrodecomanda", nrodecomanda);
    console.log("e", codcli);
    if (codcli !== "") {
      var sp1=document.createTextNode(" : "+ razonsocial)
      console.log("hola");
      setGuardar(true);
      setShow(true);
      localStorage.setItem("codcli", codcli);
      localStorage.setItem("lista", lista);
      document.getElementById("lista").disabled = true;
      document.getElementById("button").disabled = true;
      const combo = document.querySelector("input.form-control");
      // combo.parentElement.removeChild(combo);
      combo.parentElement.replaceChild(sp1, combo);
   
    }
    else
        alert("Error - Seleccione correctamente el Cliente")
  };

  const [clientes, setClientes] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getClientesActivos(clienteSelect).then((clientes) => {
      setClientes({
        data: clientes,
        loading: false,
      });
    });
  }, [clienteSelect]);

  const [listas, setListas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getListas().then((listas) => {
      setListas({
        data: listas,
        loading: false,
      });
    });
  }, []);

  const [usuarios, setUsuarios] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getUsuarios().then((usuarios) => {
      setUsuarios({
        data: usuarios,
        loading: false,
      });
    });
  }, []);

  var arraycliente = [];
  if (!clientes.loading) {
    for (let i = 0; i < clientes.data.clientes.length; i++) {
      arraycliente[i] = {
        text: clientes.data.clientes[i].razonsocial,
        value: clientes.data.clientes[i]._id,
      };
    }
  }

  return (
    <>

      {!showDatesRange &&
        <form>
          <div className="row justify-content-center">
            <div className="col-2">
              <h4 className="ml-3 mr-3 mt-3">Ingrese Cliente  
                <input className="mt-3 mb-3"
                  type="text" 
                  name="cli" 
                  id="clienteSelect" 
                  required 
                />
              </h4>
            </div>
            <div className="col-2">
              <button 
                type="button"  
                className="btn btn-danger ml-5 mt-3 mb-3 justify-content-end" 
                onClick={() => getClienteSelec()}> Aceptar </button>
              </div>
          </div>  
        </form>
      }

      {showDatesRange &&
        <>
          {!clientes.loading && !listas.loading && !usuarios.loading && (
            
            <form onSubmit={handleSubmit}>
              <div className="container">
                <div className="form-row">

                  <div className="form-group col-sm-4 mb-2">
                    <label className="" key={clientes._id}>Cliente</label>
                    <ReactHTMLDatalist
                      classNames={"form-control"}
                      options={arraycliente}
                      required
                      onChange={(e) => {
                        setCodcli(e.target.value); 
                        // console.log(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-2">
                    <label className="">Lista</label>
                    <select
                      type="text"
                      className="form-control"
                      name="lista"
                      id="lista"
                      maxLength="30"
                      required
                      onChange={(e) => {
                        setLista(e.target.value);
                      }}
                    >
                      <option selected value="">
                        Elija opción
                      </option>
                      {listas.data.listas.map((lista) => (
                        <option value={lista._id}>{lista.lista}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <button
                      type="submit"
                      className="btn btn-dark mt-4 mb-3"
                      id="button"
                      // onsubmit="return false"
                      //onClick={guardar ? ActualizaComanda() : null}
                    >
                      Guardar Cabecera
                    </button>
                  </div>
                  <div className="col-2">
                    <button  
                      type="text"        
                      className="btn btn-primary btn-sm ml-5 mt-4 mb-3"    
                      onClick={() => window.location.reload()}
                    >
                      Recargar Pagina...
                    </button>
                  </div>
                </div>       
              </div>
            </form>
          )}
          {/* {guardar ? ActualizaComanda() : null} */}
          {/* <AddFormDynamics guardar /> */}
          {show ? <AddFormDynamics guardar /> : null}
        </>
      }
    </>
  );
};
export default AddComandaForm;
