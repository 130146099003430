import React from "react";
// import TableComandas from "../components/TableComandas";
import AppAlumnoReactTable from "../table/AppAlumnoReactTable";

import Footer from "../components/Footer";

import "../css/footer.css";

const Alumnos = () => {
  return (
    <>
      <AppAlumnoReactTable />
      {/* <TableComandas /> */}
      <Footer />
    </>
  );
};

export default Alumnos;
