import React from "react";
// import Principal from "../components/Principal";
//import Login from "../pages/Login";
import Footer from "../components/Footer";
//import logo from "../images/distripollo.jpeg";
import alumno from "../images/alumno.jpeg";
import docente from "../images/avatardocente.png";
import gasto from "../images/gasto.png";
import cuota from "../images/cuota.jpg";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import "../css/home.css";
import "../css/footer.css";

const Home = () => {
  return (
    <>
      {/* <Login /> */}
      <div className="container mt-5">
        <hr />          
        <div className="row text-center mb-3">
          <div className="col-12">
            <h1>Gestion de Datos</h1>
          </div>
        </div>

        <div className="row mb-5 mt-5">

          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/alumnos">
              <div className="box-wrapper color-docente">
                <img className="logo" src={alumno} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3 "></Navbar.Brand>
                <h3 className="h3">Alumnos</h3>
              </div>
            </Link>
          </div>
          
          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/docentes">
              <div className="box-wrapper color-docente">
                <img className="logo" src={docente} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3 "></Navbar.Brand>
                <h3 className="h3">Docentes</h3>
              </div>
            </Link>
          </div>

          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="/gastos">
              <div className="box-wrapper color-gasto">
                <img className="logo" src={gasto} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3 "></Navbar.Brand>
                <h3 className="h3">Gastos</h3>
              </div>
            </Link>
          </div>

          <div className="col-3 mb-5 mt-5">
            <Link className="nav" to="">
              <div className="box-wrapper color-cuotas">
                <img className="logo" src={cuota} alt="logo" href="/clientes" />
                <Navbar.Brand className="mt-3 "></Navbar.Brand>
                <h3 className="h3">Cuotas</h3>
              </div>
            </Link>
          </div>


        </div>
          
      </div>
      <Footer />
    </>
  );
};

export default Home;
