import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getAlumnos, delAlumno, getAlumnoId } from "../helpers/rutaAlumnos";
import ModalAlumno from "./ModalAlumno";
import "../css/tablealumnos.css";

const TableAlumnos = () => {
  let id_alumno = "";

  const [alumnos, setAlumnos] = useState({
    data: {},
    loading: true,
  });

  const [alumno, setAlumno] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    consultaAlumnos();
  }, []);

  const consultaAlumnos = () => {
    getAlumnos().then((datos) => {
      console.log(datos);
      setAlumnos({
        data: datos,
        loading: false,
      });
    });
  };

  // console.log(comandas.data[0]);

  const handleClose = () => {
    setShow(false);
    consultaAlumnos();
  };

  const handleShow = () => setShow(true);

  const modificaAlumno= (id) => {
    id_alumno = id;
    getAlumnoId(id_alumno).then((resp) => {
      console.log(resp);
      setAlumno(resp);

      handleShow();
    });
  };

  const deleteAlumno = (id) => {
    let validar = window.confirm("está seguro que desea borrar el alumno?");
    if (validar) {
      delAlumno(id).then((resp) => {
        consultaAlumnos();
      });
    }
  };

  let count = 0;

  return (
    <div className="table-container">
      {!alumnos.loading && (
        <>
          <div className="col-12 mt-4"></div>
          <div>
            <Table striped bordered className="table-container">
              <thead>
                <tr className="header">
                  <th className="">Modif/Elim</th>
                  <th className="">Nro</th>
                  <th className="cli">Apellido</th>
                  <th className="dom">Nombre</th>
                  <th className="cuit">DNI</th>
                  <th className="cli">Fec. Nac.</th>
                  <th className="dom">Domicilio</th>
                  <th className="dom">Sede</th>
                  <th className="dom">Horario Jar</th>
                  <th className="tel">Foto</th>
                  <th className="tel">Paña</th>
                  <th className="dom">Ape Mama</th>
                  <th className="email">Email Mama</th>
                  <th className="email">Tel Mama</th>
                  <th className="dom">Ocupac Mama</th>
                  <th className="dom">Ape Papa</th>
                  <th className="email">Email Papa</th>
                  <th className="email">Tel Papa</th>
                  <th className="dom">Ocupac Papa</th>
                  <th className="dom">Alergia</th>
                  <th className="dom">Observaciones</th>
                  <th className="dom">Retira</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {alumnos.data.alumnos.map((alumno) => (
                  <>
                    {alumno.activo && (
                      <tr key={alumno._id}>

                        <td className="sticky1">
                          <button
                            id="acepto"
                            className="btn btn-primary"
                            onClick={() => {
                              modificaAlumno(alumno._id);
                            }}
                          >
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            id="acepto"
                            className="btn btn-danger ml-2"
                            onClick={() => {
                              deleteAlumno(alumno._id);
                            }}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>   

                        <td className="stick1">{alumno.codalu}</td>
                        <td>{alumno.apellido}</td>
                        <td>{alumno.nombre}</td>
                        <td>{alumno.dni}</td>
                        <td>{alumno.fecnac.toLocaleString("en-GB").substr(0, 10)}</td>
                        <td>{alumno.domicilio}</td>
                        <td>{alumno.sede}</td>
                        <td>{alumno.horajar}</td>
                        <td>{alumno.autofo}</td>
                        <td>{alumno.autopa}</td>
                        <td>{alumno.apemama}</td>
                        <td>{alumno.emailmama}</td>
                        <td>{alumno.telmama}</td>
                        <td>{alumno.ocumama}</td>
                        <td>{alumno.apepapa}</td>
                        <td>{alumno.emailpapa}</td>
                        <td>{alumno.telpapa}</td>
                        <td>{alumno.ocupapa}</td>
                        <td>{alumno.alergia}</td>
                        <td>{alumno.observa}</td>
                        <td>{alumno.retira}</td>

                        {/* <td>{cliente.lista.lista}</td>
                    <td>{cliente.codprod.descripcion}</td> */}
                        {/* <td>{usuario.nombre}</td>*/}
                        {/* {(count = count + 1)} */}

                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
            {/* <h1>{count}</h1> */}
          </div>
          <ModalAlumno
            show={show}
            handleClose={handleClose}
            alumno={alumno}
          />
        </>
      )}
    </div>
  );
};

export default TableAlumnos;
