import React from "react";
// import TableComandas from "../components/TableComandas";
import AppDocenteReactTable from "../table/AppDocenteReactTable";

import Footer from "../components/Footer";

import "../css/footer.css";

const Docentes = () => {
  return (
    <>
      <AppDocenteReactTable />
      {/* <TableComandas /> */}
      <Footer />
    </>
  );
};

export default Docentes;
