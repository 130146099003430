import React, { useState, useEffect } from "react";
import { addCuenta } from "../helpers/rutaCuentas";
import { getGrupos } from "../helpers/rutaGrupos";
import { getCuentas } from "../helpers/rutaCuentas";
//import { getIva } from "../helpers/rutaIva";
import "../css/addclienteform.css";

const AddCuentaForm = ({ setShow }) => {
  //const id = JSON.parse(localStorage.getItem("id"));
  const [formValues, setFormValues] = useState({
    cuenta: "",
    valor: "",
    grupo: "",
    //activo: "",

    // usuario: id,
  });
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addCuenta(formValues).then((resp) => {
      console.log(resp);
      setFormValues({
        cuenta: "",
        valor: "",
        grupo: "",
      });
      alert("Grabado Exitoso !!!")
      //   setShow(false);
    });
  };

  const [cuentas, setCuentas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getCuentas().then((cuentas) => {
      setCuentas({
        data: cuentas,
        loading: false,
      });
    });
  }, []);

  const [grupos, setGrupos] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getGrupos().then((grupos) => {
      setGrupos({
        data: grupos,
        loading: false,
      });
    });
  }, []);

  return (
    <>
      {!cuentas.loading && !grupos.loading &&(
        <div className="container">
          {/* {localidades.data.localidades.map((localidad) => (
            <h3>{localidad.localidad}</h3>
          ))} */}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="form-group mt-3 col-sm-6">
                <label className="">Cuentas</label>
                <input
                  type="text"
                  className="form-control"
                  name="cuenta"
                  maxLength="30"
                  required
                  value={formValues.cuenta}
                  onChange={handleChange}
                />
                {console.log(formValues.cuenta)}
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Grupo</label>
                <select
                  className="form-control"
                  name="grupo"
                  value={formValues.grupo}
                  onChange={handleChange}
                  required
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  {grupos.data.grupos.map((grupo) => (
                    <option value={grupo._id}>{grupo.grupo}</option>
                  ))}
                </select>
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="form-group">Valor 1 / -1</label>
                <input
                  type="text"
                  className="form-control"
                  name="valor"
                  maxLength="2"
                  required
                  value={formValues.valor}
                  onChange={handleChange}
                />
                {console.log(formValues.valor)}
              </div>
              
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-dark mt-5 mb-3"
                id="button"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AddCuentaForm;
