import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { getDocentes, delDocente, getDocenteId } from "../helpers/rutaDocentes";
import ModalDocente from "./ModalDocente";
import "../css/tabledocentes.css";

const TableDocentes = () => {
  let id_docente = "";

  const [docentes, setDocentes] = useState({
    data: {},
    loading: true,
  });

  const [docente, setDocente] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    consultaDocentes();
  }, []);

  const consultaDocentes = () => {
    getDocentes().then((datos) => {
      console.log(datos);
      setDocentes({
        data: datos,
        loading: false,
      });
    });
  };

  // console.log(comandas.data[0]);

  const handleClose = () => {
    setShow(false);
    consultaDocentes();
  };

  const handleShow = () => setShow(true);

  const modificaDocente= (id) => {
    id_docente = id;
    getDocenteId(id_docente).then((resp) => {
      console.log(resp);
      setDocente(resp);

      handleShow();
    });
  };

  const deleteDocente = (id) => {
    let validar = window.confirm("está seguro que desea borrar el docente?");
    if (validar) {
      delDocente(id).then((resp) => {
        consultaDocentes();
      });
    }
  };

  let count = 0;

  return (
    <div className="table-container">
      {!docentes.loading && (
        <>
          <div className="col-12 mt-4"></div>
          <div>
            <Table striped bordered hover className="table-container">
              <thead>
                <tr className="header">
                  <th className="th">Modif/Elim</th>
                  <th className="">Nro</th>
                  <th className="cli">Apellido</th>
                  <th className="cli">Nombre</th>
                  <th className="dni">DNI</th>
                  <th className="dom">Domicilio</th>
                  <th className="fec">Fec. Nac.</th>
                  <th className="fec">Fec. Ing.</th>
                  <th className="email">Email</th>
                  <th className="email">Tel</th>
                  <th className="cuit">Horario</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {docentes.data.docentes.map((docente) => (
                  <>
                    {docente.activo && (
                      <tr key={docente._id}>
                        <td className="sticky1">
                          <button
                            id="acepto"
                            className="btn btn-primary"
                            onClick={() => {
                              modificaDocente(docente._id);
                            }}
                          >
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            id="acepto"
                            className="btn btn-danger ml-2"
                            onClick={() => {
                              deleteDocente(docente._id);
                            }}
                          >
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                          </button>
                        </td>
                        <td>{docente.coddoc}</td>
                        <td>{docente.apellido}</td>
                        <td>{docente.nombre}</td>
                        <td>{docente.dni}</td>
                        <td>{docente.domicilio}</td>
                        <td>{docente.fecnac.toLocaleString("en-GB").substr(0, 10)}</td>
                        <td>{docente.fecing.toLocaleString("en-GB").substr(0, 10)}</td>
                        <td>{docente.email}</td>
                        <td>{docente.tel}</td>
                        <td>{docente.horario}</td>
                        {/* <td>{cliente.lista.lista}</td>
                    <td>{cliente.codprod.descripcion}</td> */}
                        {/* <td>{usuario.nombre}</td>*/}
                        {/* {(count = count + 1)} */}

                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
            {/* <h1>{count}</h1> */}
          </div>
          <ModalDocente
            show={show}
            handleClose={handleClose}
            docente={docente}
          />
        </>
      )}
    </div>
  );
};

export default TableDocentes;
