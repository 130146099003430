import axios from "axios";
import qs from "qs";
//Traer todos los cursos con el limite y desde que registro
export const getInvoice = async () => {
  let url = `https://backdistripruebas.us-3.evennode.com/invoices`;
  const options = {
    method: "GET",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  try {
    const resp = await axios(url, options);
    const { data } = resp;
    return data;
  } catch (error) {
    return {
      data: error.response.data,
      loading: false,
    };
  }
};
//Traer un curso según su id
export const getInvoiceId = async (nrodecomanda) => {
  let url = `https://backdistripruebas.us-3.evennode.com/invoices/${nrodecomanda}`;
  const options = {
    method: "GET",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  try {
    const resp = await axios(url, options);
    const { data } = resp;
    return data;
  } catch (error) {
    return {
      data: error.response.data,
      loading: false,
    };
  }
};
// export const getCarousel = async () => {
//   const resp = await axios("https://damp-falls-98546.herokuapp.com/");
//   const { data } = resp;
//   return data;
// };
// export const getMarquesina = async () => {
//   const resp = await axios("https://damp-falls-98546.herokuapp.com/");
//   const { data } = resp;
//   return data;
// };
// Crear nuevo empresa
// export const addComanda = async (datos) => {
//   // e.preventDefault();
//   console.log(datos);
//   // const token = JSON.parse(localStorage.getItem("token")) || "";
//   let url = "https://backdistripruebas.us-3.evennode.com/comandas";
//   const options = {
//     method: "POST",
//     headers: {
//       "content-type": "application/x-www-form-urlencoded",
//       // token: token,
//     },
//     // data: datos
//         data:qs.stringify(datos)
//   };
//   try {
//     const resp = await axios(url, options);
//     const { data } = resp;
//     console.log(resp);
//     return data;
//   } catch (error) {
//     // console.log(error.response.data);
//     return {
//       data: error.response.data,
//       loading: false,
//     };
//   }
// };

// Actualizar Propiedad
// export const modifCliente = async (datos, id) => {
//   // console.log(datos);
//   // const token = JSON.parse(localStorage.getItem("token")) || "";
//   let url = `https://backdistripruebas.us-3.evennode.com/clientes/${id}`;
//   const options = {
//     method: "PUT",
//     headers: {
//       "content-type": "application/x-www-form-urlencoded",
//       // token: token,
//     },
//     data: qs.stringify(datos),
//   };
//   try {
//     const resp = await axios(url, options);
//     const { data } = resp;
//     console.log(data);
//     return data;
//   } catch (error) {
//     console.log(error.response.data);
//     return {
//       data: error.response.data,
//       loading: false,
//     };
//   }
// };
// Inactivar un propiedad

// export const delPropiedad = async (id) => {
//   const token = JSON.parse(localStorage.getItem("token")) || "";
//   let url = `https://backdistripruebas.us-3.evennode.com/empresas/${id}`;
//   const options = {
//     method: "DELETE",
//     headers: {
//       "content-type": "application/x-www-form-urlencoded",
//       // token: token,
//     },
//   };
//   try {
//     const resp = await axios(url, options);
//     const { data } = resp;
//     // console.log(data);
//     return data;
//   } catch (error) {
//     // console.log(error.response.data);
//     return {
//       data: error.response.data,
//       loading: false,
//     };
//   }
// };
