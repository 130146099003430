import React, { useState, useEffect } from "react";
import { getDocentes, modificaDocente, getDocenteId } from "../helpers/rutaDocentes";
import { delDocente } from "../helpers/rutaDocentes";
import styled from "styled-components";
import { useTable, useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import axios from "axios";
import _ from "lodash";
import Table from "./TableContainerDocente";
import { SelectColumnFilter } from "./Filter";
import ModalDocente from "../components/ModalDocente";
import "../css/tabledocentes.css";
// import "./App.css";

function AppDocenteReactTable() {
  const [show, setShow] = useState(false);
  const [docentes, setDocentes] = useState({
    data: {},
    loading: true,
  });
  const [docente, setDocente] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    axios("https://backdistripruebas.us-3.evennode.com/docentes")
      .then((res) => {
        setData(res.data.docentes);
      })
      .catch((err) => console.log(err));
  }, []);

  const Styles = styled.div`
  sticky: true;  
  padding: 0rem;
  
  table {
    sticky: true;
    //background-color: #548fcd;
    color: black;
    border-spacing: 0;
    border: 1px solid black;
    font-size: 13px;
    z-index: 1;

    th {
      sticky: true;
      background-color: #778899;
      font-size: 12px;
      text-align: center;
      height: 10rem;
      // position: sticky;
      top: 100;
      z-index: 1;
    }
    ,
    td {
      sticky: true;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      // background-color: #548f0a;
      //background-color: #f0f2eb;
      font-size: 13px;
      // top: 100;
      // z-index: 1;
      

      :last-child {
        border-right: 0;
      }

      input {
        // sticky: true;
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 2;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
    //este me da el color de la barra de busqueda
    //background-color: #548fcd;
    font-size: 15px;
    font-weight: bold;
  }
  
  &.sticky {
    overflow: scroll;
    header,
    footer {
      position: sticky;
      z-index: 1;
      // width: fit-content;
    }
  }
    
    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
      position: sticky;
      z-index:10;
      
    }

    [data-sticky-td] {
      // position: sticky;
      position: absolute;
      z-index: 0;
    }


`;



  // fn filtro fecha

  function dateBetweenFilterFn(rows, id, filterValues) {
    const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
    const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;

    if (ed || sd) {
      return rows.filter((r) => {
        const cellDate = new Date(r.values[id]);

        if (ed && sd) {
          return cellDate >= sd && cellDate <= ed;
        } else if (sd) {
          return cellDate >= sd;
        } else if (ed) {
          return cellDate <= ed;
        }
      });
    } else {
      return rows;
    }
  }

  // filtro fecha

  
  function DateRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length
        ? new Date(preFilteredRows[0].values[id])
        : new Date(0);
      let max = preFilteredRows.length
        ? new Date(preFilteredRows[0].values[id])
        : new Date(0);

      preFilteredRows.forEach((row) => {
        const rowDate = new Date(row.values[id]);

        min = rowDate <= min ? rowDate : min;
        max = rowDate >= max ? rowDate : max;
      });

      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div>
        <input
          min={min.toISOString().slice(0, 10)}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? val : undefined, old[1]]);
          }}
          type="date"
          style={{
            width: "100px",
            marginRight: "0.5rem",
            marginBottom: "2.2rem",
          }}
          value={filterValue[0] || ""}
        />
        {/* {" a "} */}
        <input
          max={max.toISOString().slice(0, 10)}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? val.concat("T23:59:59.999Z") : undefined,
            ]);
          }}
          type="date"
          style={{
            width: "100px",
            marginRight: "0.5rem",
            marginBottom: "2.2rem",
          }}
          value={filterValue[1]?.slice(0, 10) || ""}
        />
      </div>
    );
  }



  //hasta ca filtro e fecha

  const consultaDocentes = () => {
    getDocentes().then((datos) => {
      // console.log(datos);
      setDocentes({
        data: datos,
        loading: false,
      });
    });
  };

  const modificaDocente = (id) => {
    let id_docente = id;
    getDocenteId(id_docente).then((resp) => {
      console.log(resp);
      setDocente(resp);
      // alert("Comanda Modificada")

      handleShow();
    });
  };

  const handleClose = () => {
    setShow(false);
    consultaDocentes();
  };

  const handleShow = () => setShow(true);

  const deleteDocente = (nrodedocente) => {
    // console.log("com", data);
    // console.log("nrodecomanda", nrodecomanda);

    const buscardocente = data.filter(function (element) {
      return element.nrodedocente === nrodedocente;
    });
    //console.log(buscacomanda);

    let validar = window.confirm(
      "Está seguro que desea borrar el Docente Nro " + nrodedocente
    );

    if (validar) {
      for (let i = 0; i < buscardocente.length; i++) {
        delDocente(buscardocente[i]._id).then((resp) => {
          // consultaComandas();
        });

        // aca suma cantidada + stock actual
        // console.log("bc[i].cod._id", buscacomanda[i].codprod._id);
        // debugger;

        // getProducservId(buscaremito[i].codprod._id).then((stk) => {
        //   console.log("stk", stk);
        //   let resstk =
        //     parseInt(stk.producservs.stkactual) -
        //     parseInt(buscaremito[i].cantidad);
        //   console.log("resstk", resstk);
        //   // debugger;
        //   modifProducserv(
        //     { stkactual: resstk },
        //     buscaremito[i].codprod._id
        //   ).then((respuesta) => {
        //     // dejar este console.log
        //     console.log(respuesta);

        //     // alert("Remito Eliminado");
        //     // debugger;
        //   });
        // });
      }
      alert("El Remito fue borrado con Exito")
      window.location.reload();
    }
  };

  console.log(data);
  const columns = [

    {
      sticky: "left",
      Header: "Modif",
      width: 50,
      // accessor: "modifica",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          <button
            id="acepto"
            className="btn btn-primary"
            onClick={(e) => modificaDocente(row.row.original._id)}
          >
            <i
              className="fa fa-pencil-square-o"
              aria-hidden="true"
              color="white"
            ></i>
          </button>
          {/* <GetDataInvoiceAdmin datacomanda={row.row.original.nrodecomanda} /> */}
        </div>
      ),
    },

    {
      sticky: "left",
      Header: "Elim",
      width: 50,
      // accessor: "elimina",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          <button
            id="acepto"
            className="btn btn-danger"
            onClick={(e) => deleteDocente(row.row.original.nrodedocente)}
          >
            <i className="fa fa-trash-o" aria-hidden="true" color="white"></i>
          </button>
          {/* <GetDataInvoiceAdmin datacomanda={row.row.original.nrodecomanda} /> */}
        </div>
      ),
    },
    
    {
      //sticky: "left",
      id: "coddoc",
      Header: "Cod",
      width: 40,
      accessor: "coddoc",
      filter: "equals",

      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },


    {
      Header: "Apellido",
      accessor: "apellido",
      width: 120,
    },

    {
      Header: "Nombre",
      accessor: "nombre",
      width: 150,
    },

    {
      Header: "Domicilio",
      accessor: "domicilio",
      width: 180,
    },

    {
      Header: "DNI",
      accessor: "dni",
      width: 70,
      //filter: "equals", este es el filtro igual igual identico ===
      // id: "cantidad",

      
      //Footer hace una sumatoria de la columna
      // Footer: (info) => {
      //   const total = React.useMemo(
      //     () => info.rows.reduce((sum, row) => row.values.dni + sum, 0),
      //     [info.rows]
      //   );

      //   return (
      //     <>
      //       <div style={{ textAlign: "center" }}>
      //         <b className="pie">{total}</b>{" "}
      //       </div>
      //     </>
      //   );
      // },
      //Esto cell la centra
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },

    {
      id: "fecnac",
      Header: "Fecha Nac",
      width: 110,
      style: { marginBottom: "2rem" },
      accessor: "fecnac",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>{row.value.slice(0, 10)}</div>
      ),

      Filter: DateRangeColumnFilter,
      // filter: "between",
      filter: dateBetweenFilterFn,
    },

    {
      id: "fecing",
      Header: "Fecha Ing.",
      width: 110,
      style: { marginBottom: "2rem" },
      accessor: "fecing",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>{row.value.slice(0, 10)}</div>
      ),

      Filter: DateRangeColumnFilter,
      // filter: "between",
      filter: dateBetweenFilterFn,
    },

    {
      Header: "Email",
      accessor: "email",
      width: 180,

      //Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },

    {
      Header: "Telefono",
      accessor: "tel",
       width: 100,
    },

    {
      Header: "Horario",
      accessor: "horario",
       width: 130,
    },


    // {
    //   Header: "Email",
    //   accessor: "codprov.email",
    // },

    // {
    //   Header: () => (
    //     <div
    //       style={{
    //         textAlign: "center",
    //       }}
    //     >
    //       Monto
    //     </div>
    //   ),

    //   accessor: "monto",

    //   Cell: (props) =>
    //     new Intl.NumberFormat("es-AR", {
    //       style: "currency",
    //       currency: "ARS",
    //     }).format(props.value),

    //   Footer: (info) => {
    //     // Only calculate total visits if rows change
    //     const total = React.useMemo(
    //       () => info.rows.reduce((sum, row) => row.values.monto + sum, 0),
    //       [info.rows]
    //     );

    //     return (
    //       <b className="pie">
    //         {new Intl.NumberFormat("es-AR", {
    //           style: "currency",
    //           currency: "ARS",
    //         }).format(total)}
    //       </b>
    //     );
    //   },

    //   // Cell: (row) => <div style={{ textAlign: "right" }}>{row.value}</div>,
    // },



    // {
    //   Header: "Impresion",
    //   // accessor: "impresion",
    //   Cell: (row) => (
    //     <div style={{ textAlign: "center" }}>
    //       {/* <button id="acepto" className="btn btn-primary" onClick={(e) => modificaComanda(row.row.original._id)}>
    //       <i className="fa fa-pencil-square-o" aria-hidden="true" ></i>
    //     </button> */}
    //       <GetDataInvoiceAdmin datacomanda={row.row.original.nrodecomanda} />
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <div className="row">
        <h2 className="col-10">
          <center>ADMINISTRACION DE DOCENTES</center>
        </h2>
        <div className="col-2">
          <button          
            className="btn btn-primary mr-5 mt-2 mb-2 justify-content-end"    
            onClick={() => window.location.reload()}
          >
            Recargar Pagina...
          </button>
        </div>
      </div>
      <Styles className="container table sticky" style={{ width: "auto", height: 400 }}>
        <div className="App">
          <Table columns={columns} data={data} />
        </div>
        <ModalDocente show={show} handleClose={handleClose} docente={docente} />
      </Styles>
    </>
  );
}

export default AppDocenteReactTable;
