import React, { useState, useEffect } from "react";
import {
  addAlumno,
  getAlumno,
  delAlumno,
  getAlumnoId,
} from "../helpers/rutaAlumnos";


//import { getLocalidades } from "../helpers/rutaLocalidades";
//import { getRutas } from "../helpers/rutaRutas";
//import { getIva } from "../helpers/rutaIva";


//import "../css/addAlumnoform.css";

const AddAlumnoForm = ({ setShow }) => {
 // const id = JSON.parse(localStorage.getItem("id"));
  const [formValues, setFormValues] = useState({
    codalu: "",
    apellido: "",
    nombre: "",
    dni: "",
    fecnac: "",
    domicilio: "",
    emailmama: "",
    telmama: "",
    apemama: "",
    emailpapa: "",
    telpapa: "",
    apepapa: "",
    sede: "",
    ocumama: "",
    ocupapa: "",
    alergia: "",
    observa: "",
    retira: "",
    autofo: "",
    autopa: "",
    horajar: "",
  //  usuario: id,
  });
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addAlumno(formValues).then((resp) => {
      console.log(resp);
      setFormValues({
        codalu: "",
        apellido: "",
        nombre: "",
        dni: "",
        fecnac: "",
        domicilio: "",
        emailmama: "",
        telmama: "",
        apemama: "",
        emailpapa: "",
        telpapa: "",
        apepapa: "",
        sede: "",
        ocumama: "",
        ocupapa: "",
        alergia: "",
        observa: "",
        retira: "",
        autofo: "",
        autopa: "",
        horajar: "",
        //usuario: id,

      });
      alert("Grabado Exitoso !!!")
      //   setShow(false);
    });
  };

  /*
  const [localidades, setLocalidades] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getLocalidades().then((localidades) => {
      setLocalidades({
        data: localidades,
        loading: false,
      });
    });
  }, []);
  */

  /*
  const [iva, setIva] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getIva().then((iva) => {
      setIva({
        data: iva,
        loading: false,
      });
    });
  }, []);
  */

  /*
  const [rutas, setRutas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getRutas().then((rutas) => {
      setRutas({
        data: rutas,
        loading: false,
      });
    });
  }, []);
  */

  return (
    <>
      {//!localidades.loading && !iva.loading && !rutas.loading && (
        <div className="container">
          {/* {localidades.data.localidades.map((localidad) => (
            <h3>{localidad.localidad}</h3>
          ))} */}

          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group mt-3 col-sm-4">
                <label className="">Apellido del Alumno</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellido"
                  maxLength="50"
                  required
                  value={formValues.apellido}
                  onChange={handleChange}
                />
                {console.log(formValues.apellido)}
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Nombre del Alumno</label>
                <input
                  type="text"
                  className="form-control"
                  name="nombre"
                  maxLength="50"
                  required
                  value={formValues.nombre}
                  onChange={handleChange}
                />
                {console.log(formValues.nombre)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">DNI del Alumno</label>
                <input
                  type="text"
                  className="form-control"
                  name="dni"
                  maxLength="8"
                  required
                  value={formValues.dni}
                  onChange={handleChange}
                />
                {console.log(formValues.dni)}
                </div>

              <div className="form-group col-sm-2">
                <label className="mt-4">Fecha Nac. Alumno</label>
                <input
                  type="date"
                  className="form-control"
                  name="fecnac"
                  id="fecnac"
                  maxLength="30"
                  required
                  value={formValues.fecnac}
                  onChange={handleChange}
                />
                {console.log(formValues.fecnac)}
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Domicilio</label>
                <input
                  type="text"
                  className="form-control"
                  name="domicilio"
                  maxLength="50"
                  required
                  value={formValues.domicilio}
                  onChange={handleChange}
                />
                {console.log(formValues.domicilio)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                  <label className="">Sede</label>
                  <select
                    className="form-control"
                    name="sede"
                    value={formValues.sede}
                    onChange={handleChange}
                    required
                  >
                    <option selected value="">
                      Elija opción
                    </option>
                    <option>Sede Norte</option>
                    <option>Sede Sur</option>
                  </select>
                </div>

                <div className="form-group mt-3 col-sm-2">
                <label className="">Horario Jardin</label>
                <input
                  type="text"
                  className="form-control"
                  name="horajar"
                  maxLength="50"
                  required
                  value={formValues.horajar}
                  onChange={handleChange}
                />
                {console.log(formValues.horajar)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                  <label className="">Autoriza Foto Redes</label>
                  <select
                    className="form-control"
                    name="autofo"
                    value={formValues.autofo}
                    onChange={handleChange}
                    required
                  >
                    <option selected value="">
                      Elija opción
                    </option>
                    <option>SI</option>
                    <option>NO</option>
                  </select>
                </div>

                <div className="form-group mt-3 col-sm-2">
                  <label className="">Autoriza Cambio Pañales</label>
                  <select
                    className="form-control"
                    name="autopa"
                    value={formValues.autopa}
                    onChange={handleChange}
                    required
                  >
                    <option selected value="">
                      Elija opción
                    </option>
                    <option>SI</option>
                    <option>NO</option>
                  </select>
                </div>

            </div>

            <div className="form-row">
              <div className="form-group mt-3 col-sm-4">
                <label className="">Apellido y Nombre Mama</label>
                <input
                  type="text"
                  className="form-control"
                  name="apemama"
                  maxLength="50"
                  required
                  value={formValues.apemama}
                  onChange={handleChange}
                />
                {console.log(formValues.apemama)}
              </div>

              <div className="form-group mt-3 col-sm-3">
                <label className="">Email Mama</label>
                <input
                  type="email"
                  className="form-control"
                  name="emailmama"
                  maxLength="50"
                  required
                  value={formValues.emailmama}
                  onChange={handleChange}
                />
                {console.log(formValues.emailmama)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Telefono Mama</label>
                <input
                  type="text"
                  className="form-control"
                  name="telmama"
                  maxLength="50"
                  required
                  value={formValues.telmama}
                  onChange={handleChange}
                />
                {console.log(formValues.telmama)}
              </div>

              <div className="form-group mt-3 col-sm-3">
                <label className="">Ocupacion Mama</label>
                <input
                  type="text"
                  className="form-control"
                  name="ocumama"
                  maxLength="50"
                  required
                  value={formValues.ocumama}
                  onChange={handleChange}
                />
                {console.log(formValues.ocumama)}
              </div>

            </div>

            <div className="form-row">
              <div className="form-group mt-3 col-sm-4">
                <label className="">Apellido y Nombre Papa</label>
                <input
                  type="text"
                  className="form-control"
                  name="apepapa"
                  maxLength="50"
                  required
                  value={formValues.apepapa}
                  onChange={handleChange}
                />
                {console.log(formValues.apepapa)}
              </div>

              <div className="form-group mt-3 col-sm-3">
                <label className="">Email Papa</label>
                <input
                  type="email"
                  className="form-control"
                  name="emailpapa"
                  maxLength="50"
                  required
                  value={formValues.emailpapa}
                  onChange={handleChange}
                />
                {console.log(formValues.emailpapa)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Telefono Papa</label>
                <input
                  type="text"
                  className="form-control"
                  name="telpapa"
                  maxLength="50"
                  required
                  value={formValues.telpapa}
                  onChange={handleChange}
                />
                {console.log(formValues.telpapa)}
              </div>

              <div className="form-group mt-3 col-sm-3">
                <label className="">Ocupacion Papa</label>
                <input
                  type="text"
                  className="form-control"
                  name="ocupapa"
                  maxLength="50"
                  required
                  value={formValues.ocupapa}
                  onChange={handleChange}
                />
                {console.log(formValues.ocupapa)}
              </div>

            </div>
          
            <div className="form-row">
              <div className="form-group mt-3 col-sm-4">
                <label className="">Alergias</label>
                  <input
                    type="text"
                    className="form-control"
                    name="alergia"
                    maxLength="50"
                    required
                    value={formValues.alergia}
                    onChange={handleChange}
                  />
                  {console.log(formValues.alergia)}
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Observaciones</label>
                  <input
                    type="text"
                    className="form-control"
                    name="observa"
                    maxLength="50"
                    required
                    value={formValues.observa}
                    onChange={handleChange}
                  />
                  {console.log(formValues.observa)}
              </div>

              <div className="form-group mt-3 col-sm-4">
                <label className="">Quien Retira</label>
                  <input
                    type="text"
                    className="form-control"
                    name="retira"
                    maxLength="50"
                    required
                    value={formValues.retira}
                    onChange={handleChange}
                  />
                  {console.log(formValues.retira)}
              </div>

            </div>

              {/*
              <div className="form-group mt-3 col-sm-2">
                <label className="">Localidad</label>
                <select
                  className="form-control"
                  name="localidad"
                  value={formValues.localidad}
                  onChange={handleChange}
                  required
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  {localidades.data.localidades.map((localidad) => (
                    <option value={localidad._id}>{localidad.localidad}</option>
                  ))}
                </select>
              </div>*/}

            <div className="form-row">
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-dark mt-5 mb-3"
                id="button"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      //)
      }
    </>
  );
};

export default AddAlumnoForm;


