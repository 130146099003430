import React, { useState, useEffect } from "react";
import { addProducserv } from "../helpers/rutaProducservs";
import { getCuentas } from "../helpers/rutaCuentas";
import "../css/addclienteform.css";

const AddProducservForm = ({ setShow }) => {
  //const id = JSON.parse(localStorage.getItem("id"));
  const [formValues, setFormValues] = useState({
    codprod: "",
    descripcion: "",
    cuenta: "",
    tipo: "",
    // usuario: id,
  });
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addProducserv(formValues).then((resp) => {
      console.log(resp);
      setFormValues({
        codprod: "",
        descripcion: "",
        cuenta: "",
        tipo: "",
    });
    alert("Grabado Exitoso !!!")
      //   setShow(false);
    });
  };

  const [cuentas, setCuentas] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getCuentas().then((cuentas) => {
      setCuentas({
        data: cuentas,
        loading: false,
      });
    });
  }, []);

  return (
    <>
      {!cuentas.loading && (
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group mt-3 col-sm-2">
                <label className="">Cod. Prod</label>
                <input
                  type="text"
                  className="form-control"
                  name="codprod"
                  maxLength="13"
                  required
                  value={formValues.codprod}
                  onChange={handleChange}
                />
                {console.log(formValues.codprod)}
              </div>

              <div className="form-group mt-3 col-sm-6">
                <label className="">Descripcion</label>
                <input
                  type="text"
                  className="form-control"
                  name="descripcion"
                  maxLength="50"
                  required
                  value={formValues.descripcion}
                  onChange={handleChange}
                />
                {console.log(formValues.descripcion)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Cuentas</label>
                <select
                  className="form-control"
                  name="cuenta"
                  value={formValues.cuenta}
                  onChange={handleChange}
                  required
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  {cuentas.data.cuentas.map((cuenta) => (
                    <option value={cuenta._id}>{cuenta.cuenta}</option>
                  ))}
                </select>
                {console.log(formValues.cuenta._id)}
              </div>

              <div className="form-group mt-3 col-sm-2">
                <label className="">Tipo</label>
                <select
                  className="form-control"
                  name="tipo"
                  value={formValues.tipo}
                  onChange={handleChange}
                  required
                >
                  <option selected value="">
                    Elija opción
                  </option>
                  <option>PRODUCTO</option>
                  <option>SERVICIO</option>
                </select>
              </div>

            </div>

            <div>
              <button
                type="submit"
                className="btn btn-dark mt-5 mb-3"
                id="button"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AddProducservForm;
