import React, { useState, useEffect } from "react";
import { modifGrupo } from "../helpers/rutaGrupos";
import { Modal, Button } from "react-bootstrap";
import { getGrupos } from "../helpers/rutaGrupos";

const ModalFormGrupo = ({ grupo, handleClose }) => {
  console.log(grupo);
  const id = localStorage.getItem("id");
  const [formValues, setFormValues] = useState({
    grupo:grupo.grupos.grupo,
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    console.log(grupo.grupos._id);
    e.preventDefault();
    modifGrupo(formValues, grupo.grupos._id).then((respuesta) => {
      console.log(respuesta);
      handleClose();
    });
  };

  const [grupos, setGrupos] = useState({
    data: {},
    loading: true,
  });
  useEffect(() => {
    getGrupos().then((grupos) => {
      setGrupos({
        data: grupos,
        loading: false,
      });
    });
  }, []);

  // const [grupos, setGrupos] = useState({
  //   data: {},
  //   loading: true,
  // });
  // useEffect(() => {
  //   getGrupos().then((grupos) => {
  //     setGrupos({
  //       data: grupos,
  //       loading: false,
  //     });
  //   });
  // }, []);

  return (
    <>
      {!grupos.loading && (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="form-group mt-3">
              <label>Grupo</label>
              <input
                rows="1"
                type="text"
                className="form-control"
                name="grupo"
                maxLength="50"
                minLength="5"
                required
                value={formValues.grupo}
                onChange={handleChange}
              />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="dark">
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      )}
      ;
    </>
  );
};

export default ModalFormGrupo;
